// src/LandingPage.js
import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

const LandingContainer = styled(Box)(({ theme }) => ({
  height: '80vh',
  marginTop: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const LandingPage = () => {
  return (
    <LandingContainer>
      <Typography 
        variant="h1" 
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
        sx={{ mb: 2 }}
      >
        Hack the World
      </Typography>
      <Typography 
        variant="h2" 
        component={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2, delay: 1 }}
        sx={{ mb: 4 }}
      >
        Welcome to the Future of Innovation
      </Typography>
      <Typography 
        variant="body1" 
        component={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 2, delay: 2 }}
        sx={{ maxWidth: '600px' }}
      >
        Join us in pushing the boundaries of technology and creativity. Whether you are a developer, designer, or tech enthusiast, we have something for you. Let's hack the world together and create something amazing.
      </Typography>
    </LandingContainer>
  );
};

export default LandingPage;
