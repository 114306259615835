// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#121212',
      paper: '#1f1f1f',
      appBar: '#1e1e1e',
      sidebar: '#242424',
    },
    text: {
      primary: '#ffffff',
      secondary: '#90caf9',
      disabled: '#bdbdbd',
      hint: '#8c8c8c',
    },
    divider: '#424242',
    action: {
      active: '#90caf9',
      hover: '#333333',
      selected: '#1c1c1c',
      disabled: '#555555',
      disabledBackground: '#333333',
    },
  },
  typography: {
    fontFamily: 'JetBrains Mono, Arial, sans-serif',
    h1: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '3rem',
      letterSpacing: '-0.01562em',
      color: '#ffffff',
    },
    h2: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '2.125rem',
      letterSpacing: '-0.00833em',
      color: '#90caf9',
    },
    h3: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '1.5rem',
      letterSpacing: '0em',
      color: '#ffffff',
    },
    h4: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '1.25rem',
      letterSpacing: '0.00735em',
      color: '#90caf9',
    },
    h5: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '1rem',
      letterSpacing: '0em',
      color: '#ffffff',
    },
    h6: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '0.875rem',
      letterSpacing: '0.0075em',
      color: '#90caf9',
    },
    body1: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontSize: '1rem',
      color: '#bdbdbd',
    },
    body2: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontSize: '0.875rem',
      color: '#bdbdbd',
    },
    button: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      color: '#ffffff',
    },
    caption: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontSize: '0.75rem',
      color: '#8c8c8c',
    },
    overline: {
      fontFamily: 'JetBrains Mono, Arial, sans-serif',
      fontSize: '0.75rem',
      fontWeight: 700,
      textTransform: 'uppercase',
      color: '#8c8c8c',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e1e',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#242424',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#1f1f1f',
          color: '#ffffff',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#333333',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#424242',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
  },
});

export default theme;
