// src/AppBarComponent.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const TransparentAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.background.default,
  boxShadow: 'none',
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-start',
}));

const AppBarComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const list = (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      sx={{ width: 250 }}
    >
      <DrawerHeader>
        <Typography variant="h6" component="div">
          Menu
        </Typography>
      </DrawerHeader>
      
      <Divider />
      
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>
        
        <Divider />

        <ListItem button component={Link} to="/tools">
        <ListItemText primary="Tools" />
        </ListItem>

        <Divider />

        <ListItem button component={Link} to="/languages">
        <ListItemText primary="Languages" />
        </ListItem>

        <Divider />

       
        <ListItem button component={Link} to="/hack-of-fame">
          <ListItemText primary="Hack Of Fame" />
        </ListItem>

        <Divider />

        <ListItem button component={Link} to="/contact">
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <TransparentAppBar position="sticky">
        <Toolbar>
          
          <Typography 
            variant="h6" 
            component={Link}
            to="/"
            sx={{ flexGrow: 1 }}
          >
            Hack the World
          </Typography>
          <IconButton 
            edge="start" 
            color="inherit" 
            aria-label="open drawer"
            aria-haspopup="true"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </TransparentAppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} sx={{ '& .MuiDrawer-paper': { backgroundColor: 'background.paper', color: 'text.primary' } }}>
        {list}
      </Drawer>
    </>
  );
};

export default AppBarComponent;
