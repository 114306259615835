import React from 'react';
import { Container, Typography, Card, CardContent, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Masonry } from '@mui/lab';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#90CAF9',
    },
  },
  typography: {
    h4: {
      color: '#90CAF9',
    },
    body1: {
      color: '#B0BEC5',
    },
  },
});

const tools = [
    { name: 'Eyewitness', description: 'A tool for screenshots of web services and applications.', link: 'https://github.com/FortyNorthSecurity/EyeWitness' },
    { name: 'Nmap', description: 'A network scanner to discover hosts and services on a network.', link: 'https://nmap.org/' },
    { name: 'Metasploit Framework', description: 'A powerful platform for developing, testing, and executing exploit code.', link: 'https://www.metasploit.com/' },
    { name: 'Wireshark', description: 'A network protocol analyzer that captures and interactively analyzes network traffic.', link: 'https://www.wireshark.org/' },
    { name: 'Burp Suite', description: 'An integrated platform for performing web application security testing.', link: 'https://portswigger.net/burp' },
    { name: 'Hydra', description: 'A tool for performing fast network logon cracking.', link: 'https://github.com/vanhauser-thc/thc-hydra' },
    { name: 'John the Ripper', description: 'A fast password cracker, primarily used to detect weak Unix passwords.', link: 'https://www.openwall.com/john/' },
    { name: 'Aircrack-ng', description: 'A suite of tools for assessing Wi-Fi network security.', link: 'https://www.aircrack-ng.org/' },
    { name: 'Nikto', description: 'A web server scanner for detecting dangerous files and vulnerabilities.', link: 'https://cirt.net/Nikto2' },
    { name: 'OWASP ZAP', description: 'An open-source web application security scanner.', link: 'https://www.zaproxy.org/' },
    { name: 'SQLmap', description: 'An automated tool for detecting and exploiting SQL injection vulnerabilities.', link: 'http://sqlmap.org/' },
    { name: 'Recon-ng', description: 'A reconnaissance framework with independent modules and database interaction.', link: 'https://github.com/lanmaster53/recon-ng' },
    { name: 'Hashcat', description: 'A password cracking tool supporting a wide variety of algorithms.', link: 'https://hashcat.net/hashcat/' },
    { name: 'DirBuster', description: 'A tool for brute-forcing directories and files on web/application servers.', link: 'https://github.com/seifreed/dirbuster' },
    { name: 'Autopsy', description: 'A digital forensics platform and graphical interface to The Sleuth Kit.', link: 'https://www.sleuthkit.org/autopsy/' },
    { name: 'Maltego', description: 'An interactive data mining tool that can connect relationships between entities.', link: 'https://www.maltego.com/' },
    { name: 'The Harvester', description: 'A tool for gathering emails, names, and other data from public sources.', link: 'https://github.com/laramies/theHarvester' },
    { name: 'Cuckoo Sandbox', description: 'An open-source automated malware analysis system.', link: 'https://cuckoosandbox.org/' },
    { name: 'Snort', description: 'An open-source intrusion detection system (IDS) for traffic analysis.', link: 'https://www.snort.org/' },
    { name: 'Splunk', description: 'A platform for operational intelligence and data analysis.', link: 'https://www.splunk.com/' },
    { name: 'Nessus', description: 'A vulnerability scanner for identifying and managing IT security risks.', link: 'https://www.tenable.com/products/nessus' },
    { name: 'OpenVAS', description: 'An open-source vulnerability scanner and manager.', link: 'https://www.openvas.org/' },
    { name: 'Kali Linux', description: 'A Linux distribution specialized for penetration testing and security auditing.', link: 'https://www.kali.org/' },
    { name: 'BackBox Linux', description: 'An Ubuntu-based distribution for penetration testing and security assessments.', link: 'https://www.backbox.org/' },
    { name: 'Parrot OS', description: 'A Debian-based OS for security and penetration testing.', link: 'https://www.parrotsec.org/' },
    { name: 'Angry IP Scanner', description: 'A fast network scanner.', link: 'https://angryip.org/' },
    { name: 'SET (Social Engineering Toolkit)', description: 'A tool for social engineering attacks.', link: 'https://github.com/trustedsec/social-engineer-toolkit' },
    { name: 'Ettercap', description: 'A tool for network protocol analysis and MITM attacks.', link: 'https://www.ettercap-project.org/' },
    { name: 'BeEF', description: 'Browser Exploitation Framework for testing browser vulnerabilities.', link: 'https://beefproject.com/' },
    { name: 'Yersinia', description: 'A tool for performing network protocol attacks.', link: 'https://www.yersinia.net/' },
    { name: 'Dmitry', description: 'A command-line tool for information gathering and network reconnaissance.', link: 'https://github.com/jaygreig86/dmitry' },
    { name: 'Hping3', description: 'A network tool for sending and analyzing TCP/IP packets.', link: 'http://www.hping.org/' },
    { name: 'Mimikatz', description: 'A tool to gather credentials from Windows systems.', link: 'https://github.com/gentilkiwi/mimikatz' },
    { name: 'Armitage', description: 'A GUI for the Metasploit Framework.', link: 'https://www.fastandeasyhacking.com/' },
    { name: 'Arachni', description: 'A web application security scanner.', link: 'https://www.arachni-scanner.com/' },
    { name: 'Chkrootkit', description: 'A tool for detecting rootkits on Linux systems.', link: 'http://www.chkrootkit.org/' },
    { name: 'Rootkit Hunter', description: 'A Unix-based tool for scanning rootkits, backdoors, and local exploits.', link: 'https://rkhunter.sourceforge.net/' },
    { name: 'Netcat', description: 'A versatile networking tool for debugging and investigation.', link: 'https://nc110.sourceforge.io/' },
    { name: 'Tcpdump', description: 'A command-line packet analyzer.', link: 'https://www.tcpdump.org/' },
    { name: 'Fiddler', description: 'A web debugging proxy for HTTP/S traffic.', link: 'https://www.telerik.com/fiddler' },
    { name: 'Sysinternals Suite', description: 'A suite of tools for Windows troubleshooting.', link: 'https://docs.microsoft.com/en-us/sysinternals/' },
    { name: 'Radare2', description: 'A framework for reverse engineering and binary analysis.', link: 'https://rada.re/n/' },
    { name: 'Ghidra', description: 'A software reverse engineering tool developed by the NSA.', link: 'https://ghidra-sre.org/' },
    { name: 'Volatility', description: 'A memory forensics framework.', link: 'https://www.volatilityfoundation.org/' },
    { name: 'ExifTool', description: 'A tool to read and write metadata in files.', link: 'https://exiftool.org/' },
    { name: 'Fierce', description: 'A DNS reconnaissance tool for locating targets within a domain.', link: 'https://github.com/mschwager/fierce' },
    { name: 'Lynis', description: 'An auditing tool for Unix-based systems.', link: 'https://cisofy.com/lynis/' },
    { name: 'Foremost', description: 'A file recovery program for Linux systems.', link: 'https://foremost.sourceforge.net/' },
    { name: 'PeStudio', description: 'A tool for analyzing executable files.', link: 'https://www.winitor.com/' },
    { name: 'Binwalk', description: 'A tool for analyzing and extracting firmware images.', link: 'https://github.com/ReFirmLabs/binwalk' },
    { name: 'ClamAV', description: 'An open-source antivirus engine for detecting trojans, viruses, and malware.', link: 'https://www.clamav.net/' },
    { name: 'Malzilla', description: 'A tool for analyzing malicious webpages.', link: 'http://malzilla.sourceforge.net/' },
    { name: 'Ncat', description: 'A networking utility for reading and writing data across networks.', link: 'https://nmap.org/ncat/' },
    { name: 'Hex Fiend', description: 'A fast, free hex editor for Mac OS X.', link: 'https://hexfiend.com/' },
    { name: 'Sysdig', description: 'A system-level exploration tool with support for containers.', link: 'https://sysdig.com/' },
    { name: 'Yara', description: 'A tool aimed at helping malware researchers to identify and classify malware samples.', link: 'https://virustotal.github.io/yara/' },
    { name: 'CyberChef', description: 'A web-based tool for performing complex data analysis and manipulation.', link: 'https://gchq.github.io/CyberChef/' },
    { name: 'Sleuth Kit', description: 'A library and collection of command-line tools for digital forensics.', link: 'https://www.sleuthkit.org/' },
    { name: 'FTK Imager', description: 'A data preview and imaging tool for digital forensics.', link: 'https://accessdata.com/product-download/ftk-imager' },
    { name: 'OSSEC', description: 'An open-source intrusion detection system.', link: 'https://www.ossec.net/' },
    { name: 'LogRhythm', description: 'A platform for security information and event management (SIEM).', link: 'https://logrhythm.com/' },
    { name: 'Graylog', description: 'An open-source log management and analysis tool.', link: 'https://www.graylog.org/' },
    { name: 'MISP', description: 'An open-source threat intelligence platform.', link: 'https://www.misp-project.org/' },
    { name: 'OpenSCAP', description: 'A security auditing tool based on SCAP standards.', link: 'https://www.open-scap.org/' },
    { name: 'PacketTotal', description: 'A free packet capture analysis service.', link: 'https://packettotal.com/' },
    { name: 'Security Onion', description: 'A Linux distro for intrusion detection and network monitoring.', link: 'https://securityonionsolutions.com/software/' },
    { name: 'NetworkMiner', description: 'A Network Forensic Analysis Tool (NFAT) for Windows.', link: 'https://www.netresec.com/?page=NetworkMiner' },
    { name: 'Canvas', description: 'An automated exploitation system with network penetration testing and vulnerability assessment capabilities.', link: 'https://www.immunityinc.com/products/canvas/' },
  { name: 'Nexpose', description: 'A vulnerability scanner and risk assessment tool.', link: 'https://www.rapid7.com/products/nexpose/' },
  { name: 'Acunetix', description: 'A web vulnerability scanner that detects and reports web application vulnerabilities.', link: 'https://www.acunetix.com/' },
  { name: 'Fimap', description: 'A local and remote file inclusion (LFI/RFI) vulnerability scanner.', link: 'https://github.com/kurobeats/fimap' },
  { name: 'Nikto', description: 'A web server scanner for detecting potentially dangerous files and outdated software.', link: 'https://cirt.net/Nikto2' },
  { name: 'Nessus', description: 'A vulnerability assessment tool that helps identify security weaknesses in systems.', link: 'https://www.tenable.com/products/nessus' },
  { name: 'Skipfish', description: 'A web application security scanner.', link: 'https://github.com/spinkham/skipfish' },
  { name: 'SecApps', description: 'A suite of security testing tools available in the browser.', link: 'https://secapps.com/' },
  { name: 'SIFT Workstation', description: 'A Linux-based forensic and incident response platform.', link: 'https://sans.org/tools/sift-workstation' },
  { name: 'Snyk', description: 'A tool for finding and fixing vulnerabilities in open-source dependencies.', link: 'https://snyk.io/' },
  { name: 'QualysGuard', description: 'A cloud-based vulnerability management and compliance solution.', link: 'https://www.qualys.com/qualysguard/' },
  { name: 'BinText', description: 'A text extraction tool for binary files.', link: 'https://www.mcafee.com/enterprise/en-us/downloads/free-tools/bintext.html' },
  { name: 'Knockpy', description: 'A python-based subdomain scanner.', link: 'https://github.com/guelfoweb/knock' },
  { name: 'Malwarebytes', description: 'An anti-malware software for detecting and removing malware.', link: 'https://www.malwarebytes.com/' },
  { name: 'Splunk', description: 'A software for searching, monitoring, and analyzing machine-generated big data.', link: 'https://www.splunk.com/' },
  { name: 'Grype', description: 'A vulnerability scanner for container images and filesystems.', link: 'https://github.com/anchore/grype' },
  { name: 'Farsight DNSDB', description: 'A database that allows users to query passive DNS data.', link: 'https://www.farsightsecurity.com/solutions/dnsdb/' },
  { name: 'Fidelis Endpoint', description: 'An endpoint detection and response (EDR) platform.', link: 'https://fidelissecurity.com/fidelis-endpoint' },
  { name: 'NetSparker', description: 'A web vulnerability scanner that automatically identifies vulnerabilities.', link: 'https://www.netsparker.com/' },
  { name: 'OpenSSH', description: 'A suite of secure networking utilities based on the Secure Shell protocol.', link: 'https://www.openssh.com/' },
  { name: 'VirusTotal', description: 'A service that analyzes files and URLs for viruses.', link: 'https://www.virustotal.com/' },
  { name: 'Burpsuite Repeater', description: 'A tool within Burp Suite for modifying and re-sending HTTP requests.', link: 'https://portswigger.net/burp/documentation/desktop/tools/repeater' },
  { name: 'ELK Stack', description: 'Elasticsearch, Logstash, and Kibana stack for log analysis.', link: 'https://www.elastic.co/what-is/elk-stack' },
  { name: 'Spyse', description: 'A data provider that continuously scans and indexes the internet.', link: 'https://spyse.com/' },
  { name: 'Amass', description: 'An in-depth DNS enumeration and attack surface mapping tool.', link: 'https://github.com/OWASP/Amass' },
  { name: 'Proxmark3', description: 'An RFID research platform.', link: 'https://proxmark.com/' },
  { name: 'Google Dorks', description: 'A technique that uses advanced Google search operators to discover security holes in web applications.', link: 'https://www.exploit-db.com/google-hacking-database' },
  { name: 'W3AF', description: 'A web application attack and audit framework.', link: 'https://w3af.org/' },
  { name: 'Brakeman', description: 'A security vulnerability scanner for Ruby on Rails applications.', link: 'https://brakemanscanner.org/' },
  { name: 'Falco', description: 'A runtime security tool for cloud-native environments.', link: 'https://falco.org/' },
  { name: 'PowerSploit', description: 'A collection of Microsoft PowerShell scripts for post-exploitation.', link: 'https://github.com/PowerShellMafia/PowerSploit' },
  { name: 'Pacu', description: 'An AWS exploitation framework.', link: 'https://github.com/RhinoSecurityLabs/pacu' },
  { name: 'Slack Extractor', description: 'A tool to extract information from Slack archives.', link: 'https://github.com/zvodd/slack-extractor' },
  { name: 'AWS Prowler', description: 'A security tool to perform AWS Security Best Practices assessments.', link: 'https://github.com/toniblyx/prowler' },
  { name: 'Gophish', description: 'An open-source phishing framework.', link: 'https://getgophish.com/' },
  { name: 'BloodHound', description: 'A tool for analyzing and attacking Active Directory relationships.', link: 'https://github.com/BloodHoundAD/BloodHound' },
  { name: 'OSQuery', description: 'An operating system instrumentation framework.', link: 'https://osquery.io/' },
  { name: 'CyberArk', description: 'A security software to protect privileged accounts.', link: 'https://www.cyberark.com/' },
  { name: 'Sigma', description: 'A generic and open signature format for SIEM systems.', link: 'https://github.com/SigmaHQ/sigma' },
  { name: 'DSNiff', description: 'A collection of tools for network auditing and penetration testing.', link: 'https://www.monkey.org/~dugsong/dsniff/' },
  { name: 'Enum4Linux', description: 'A Linux enumeration tool for Windows/Samba information gathering.', link: 'https://github.com/CiscoCXSecurity/enum4linux' },
  { name: 'Rapid7 InsightVM', description: 'A vulnerability management solution.', link: 'https://www.rapid7.com/products/insightvm/' },
  { name: 'APKiD', description: 'A malware identifier for Android APKs.', link: 'https://github.com/rednaga/APKiD' },
  { name: 'Detect It Easy', description: 'A program for detecting file types.', link: 'https://github.com/horsicq/Detect-It-Easy' },
  { name: 'Chisel', description: 'A fast TCP/UDP tunnel over HTTP.', link: 'https://github.com/jpillora/chisel' },
  { name: 'Kismet', description: 'A Wi-Fi network detector, sniffer, and intrusion detection system.', link: 'https://kismetwireless.net/' },
  { name: 'King Phisher', description: 'A phishing campaign toolkit.', link: 'https://github.com/securestate/king-phisher' },
  { name: 'Bettercap', description: 'A powerful, flexible, and portable network recon and attack tool.', link: 'https://github.com/bettercap/bettercap' },
  { name: 'Empire', description: 'A post-exploitation framework.', link: 'https://github.com/BC-SECURITY/Empire' },
  { name: 'Viper', description: 'A binary analysis and management framework.', link: 'https://viper-framework.readthedocs.io/' },
  { name: 'PyREBox', description: 'A Python scriptable reverse engineering sandbox.', link: 'https://github.com/Cisco-Talos/pyrebox' },
  { name: 'pwntools', description: 'A CTF framework and exploit development library.', link: 'https://github.com/Gallopsled/pwntools' },
  { name: 'Ghidra', description: 'A free software reverse engineering suite developed by NSA.', link: 'https://ghidra-sre.org/' },
  { name: 'Free Hex Editor Neo', description: 'A binary file editor for Windows.', link: 'https://www.hhdsoftware.com/free-hex-editor' },
  { name: 'OpenVAS', description: 'An open-source vulnerability scanner and manager.', link: 'https://www.openvas.org/' },
  { name: 'Darktrace', description: 'An AI-driven cyber defense platform.', link: 'https://www.darktrace.com/' },
  { name: 'CrowdStrike Falcon', description: 'A cloud-delivered endpoint protection platform.', link: 'https://www.crowdstrike.com/' },
  { name: 'QRadar', description: 'A SIEM product by IBM for log and flow analysis.', link: 'https://www.ibm.com/security/security-information-event-management' },
  { name: 'OSINT Framework', description: 'A collection of OSINT tools for information gathering.', link: 'https://osintframework.com/' },
  { name: 'Fibratus', description: 'A tool for exploration and tracing of the Windows kernel.', link: 'https://github.com/rabbitstack/fibratus' },
  { name: 'LeakLooker', description: 'A tool for searching open databases for data leakage.', link: 'https://github.com/woj-ciech/LeakLooker' },
  { name: 'Fail2Ban', description: 'An intrusion prevention software framework.', link: 'https://www.fail2ban.org/' },
  { name: 'RITA', description: 'A Real Intelligence Threat Analytics tool.', link: 'https://github.com/activecm/rita' },
  { name: 'Vuls', description: 'An agentless vulnerability scanner for Linux/FreeBSD.', link: 'https://github.com/future-architect/vuls' },
];
  

const ToolsPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" style={{ padding: '2rem' }}>
        <Typography variant="h4" align="center" gutterBottom style={{ color: '#90CAF9', fontFamily: 'Roboto Mono, monospace' }}>
          Tools
        </Typography>
        <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
          {tools.map((tool, index) => (
           
              <Card 
                style={{
                  backgroundColor: '#0A0A0A',
                  color: '#00FF41', // Matrix green
                  border: '1px solid #00FF41',
                  fontFamily: 'Roboto Mono, monospace',
                  transition: 'transform 0.2s ease-in-out',
                  boxShadow: '0 0 10px rgba(0, 255, 65, 0.2)',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 0 15px rgba(0, 255, 65, 0.6)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 255, 65, 0.2)';
                }}
              >
                <CardContent>
                  <Typography variant="h6" style={{ color: '#90CAF9', fontFamily: 'Roboto Mono, monospace' }}>
                    {tool.name}
                  </Typography>
                  <Typography variant="body1" style={{ color: '#CCCCCC', fontSize: '0.9rem', marginTop: '0.5rem' }}>
                    {tool.description}
                  </Typography>
                  <Link href={tool.link} target="_blank" rel="noopener" style={{ color: '#90CAF9', textDecoration: 'underline', marginTop: '1rem', display: 'inline-block' }}>
                    Learn More
                  </Link>
                </CardContent>
              </Card>
           
          ))}
        </Masonry>
      </Container>
    </ThemeProvider>
  );
};

export default ToolsPage;

