import React from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const ContactPage = () => {
  return (
    <StyledContainer>
      <Typography variant="h2" component="h1" gutterBottom>
        Contact Us
      </Typography>
      <Box
        component="form"
        name="htw-contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        sx={{ mt: 4, width: '100%', maxWidth: '600px' }}
      >
        <input type="hidden" name="form-name" value="htw-contact" />
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Subject"
          name="subject"
          variant="outlined"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Message"
          name="message"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Send
        </Button>
      </Box>
    </StyledContainer>
  );
};

export default ContactPage;
