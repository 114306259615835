import React from 'react';
import { Container, Typography, Card, CardContent, Link, Avatar } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HackerImage from './HackOfFame.png'; // Placeholder for actual image imports or default image.
import { Masonry } from '@mui/lab';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    text: {
      primary: '#90CAF9',
      secondary: '#B0BEC5',
    },
  },
  typography: {
    h4: {
      color: '#90CAF9',
      fontFamily: 'Roboto Mono, monospace',
    },
    body1: {
      color: '#B0BEC5',
      fontFamily: 'Roboto Mono, monospace',
    },
  },
});

const hackers = [
  {
    name: 'Kevin Mitnick',
    description: 'Once the FBI’s “Most Wanted” hacker, known for his social engineering skills.',
    link: 'https://en.wikipedia.org/wiki/Kevin_Mitnick',
  },
  {
    name: 'Walter O’Brien',
    description: 'Known for his high IQ and founding Scorpion, a cybersecurity company.',
    link: 'https://en.wikipedia.org/wiki/Walter_O%27Brien',
  },
  {
    name: 'Gary McKinnon',
    description: 'Allegedly hacked 97 U.S. military and NASA computers, seeking UFO evidence.',
    link: 'https://en.wikipedia.org/wiki/Gary_McKinnon',
  },
  {
    name: 'Adrian Lamo',
    description: 'Known as the “Homeless Hacker,” he gained fame by hacking into major companies.',
    link: 'https://en.wikipedia.org/wiki/Adrian_Lamo',
  },
  {
    name: 'Anonymous',
    description: 'An international hacktivist group known for numerous cyber-attacks and protests.',
    link: 'https://en.wikipedia.org/wiki/Anonymous_(hacker_group)',
  },
  {
    name: 'Julian Assange',
    description: 'Founder of WikiLeaks, responsible for leaking classified documents.',
    link: 'https://en.wikipedia.org/wiki/Julian_Assange',
  },
  {
    name: 'Tsutomu Shimomura',
    description: 'Helped track down and capture Kevin Mitnick, his knowledge of security is legendary.',
    link: 'https://en.wikipedia.org/wiki/Tsutomu_Shimomura',
  },
  {
    name: 'LulzSec',
    description: 'A hacker group that performed high-profile attacks “for the lulz.”',
    link: 'https://en.wikipedia.org/wiki/LulzSec',
  },
  {
    name: 'Matthew Bevan & Richard Pryce',
    description: 'Hacked military systems, leading to an international incident between the U.S. and Korea.',
    link: 'https://en.wikipedia.org/wiki/Bevan_and_Pryce_case',
  },
  {
    name: 'Albert Gonzalez',
    description: 'Leader of a cybercrime ring, stole millions of credit card numbers.',
    link: 'https://en.wikipedia.org/wiki/Albert_Gonzalez',
  },
  {
    name: 'Gary Kovacs',
    description: 'Developed innovations in cybersecurity and privacy.',
    link: 'https://en.wikipedia.org/wiki/Gary_Kovacs',
  },
  {
    name: 'Parisa Tabriz',
    description: 'Known as “Google’s Security Princess,” she leads Chrome security efforts.',
    link: 'https://en.wikipedia.org/wiki/Parisa_Tabriz',
  },
  {
    name: 'Joanna Rutkowska',
    description: 'Known for her work on stealth malware and Qubes OS for privacy-focused computing.',
    link: 'https://en.wikipedia.org/wiki/Joanna_Rutkowska',
  },
  {
    name: 'Robert Tappan Morris',
    description: 'Creator of the first computer worm, known as the Morris Worm, which impacted early internet networks.',
    link: 'https://en.wikipedia.org/wiki/Robert_Tappan_Morris',
  },
  {
    name: 'Kevin Poulsen',
    description: 'A former black-hat hacker known as “Dark Dante,” famous for taking over phone lines to win a radio station contest.',
    link: 'https://en.wikipedia.org/wiki/Kevin_Poulsen',
  },
  {
    name: 'Jonathan James',
    description: 'Known as “c0mrade,” he was the first juvenile to be incarcerated for cybercrime in the United States.',
    link: 'https://en.wikipedia.org/wiki/Jonathan_James',
  },
  {
    name: 'George Hotz',
    description: 'Also known as “GeoHot,” he was the first person to unlock the iPhone and jailbreak the PlayStation 3.',
    link: 'https://en.wikipedia.org/wiki/George_Hotz',
  },
  {
    name: 'Mark Abene',
    description: 'Known as “Phiber Optik,” he was a key member of the hacker group Masters of Deception.',
    link: 'https://en.wikipedia.org/wiki/Mark_Abene',
  },
  {
    name: 'Jeanson James Ancheta',
    description: 'A hacker who created large botnets and used them to launch DDoS attacks and rent them out for profit.',
    link: 'https://en.wikipedia.org/wiki/Jeanson_James_Ancheta',
  },
  {
    name: 'Astro',
    description: 'Part of the hacking group known as “Cult of the Dead Cow,” credited with developing Back Orifice, a remote access tool.',
    link: 'https://en.wikipedia.org/wiki/Cult_of_the_Dead_Cow',
  },
  {
    name: 'Hector Monsegur',
    description: 'Known as “Sabu,” he was a key figure in the hacking group LulzSec and later became an FBI informant.',
    link: 'https://en.wikipedia.org/wiki/Hector_Monsegur',
  },
  {
    name: 'Susan Headley',
    description: 'One of the earliest known female hackers, she was part of the hacker group known as the “Legion of Doom.”',
    link: 'https://en.wikipedia.org/wiki/Susan_Thunder',
  },
  {
    name: 'Aaron Swartz',
    description: 'Co-founder of Reddit and a brilliant programmer who advocated for open access to information.',
    link: 'https://en.wikipedia.org/wiki/Aaron_Swartz',
  },
  {
    name: 'Weev (Andrew Auernheimer)',
    description: 'An internet troll and hacker known for exploiting vulnerabilities in AT&T’s iPad network.',
    link: 'https://en.wikipedia.org/wiki/Andrew_Auernheimer',
  },
  {
    name: 'Barnaby Jack',
    description: 'Known for his work in hardware hacking, he demonstrated ATM jackpotting and other vulnerabilities.',
    link: 'https://en.wikipedia.org/wiki/Barnaby_Jack',
  },
  {
    name: 'Charlie Miller',
    description: 'A security researcher known for hacking Apple products and his work in car hacking.',
    link: 'https://en.wikipedia.org/wiki/Charlie_Miller_(security_researcher)',
  },
  {
    name: 'Chris Valasek',
    description: 'Worked with Charlie Miller to expose vulnerabilities in automotive systems, demonstrating remote car hacking.',
    link: 'https://en.wikipedia.org/wiki/Chris_Valasek',
  },
  {
    name: 'Gary Alford',
    description: 'IRS agent credited with helping uncover the identity of Silk Road founder, Ross Ulbricht.',
    link: 'https://en.wikipedia.org/wiki/Silk_Road_(marketplace)',
  },
  {
    name: 'Ross Ulbricht',
    description: 'Known as “Dread Pirate Roberts,” he created the Silk Road, a darknet marketplace for illegal goods.',
    link: 'https://en.wikipedia.org/wiki/Ross_Ulbricht',
  },
  {
    name: 'Kim Dotcom',
    description: 'Founder of the file-sharing website Megaupload, involved in high-profile legal cases around digital copyright.',
    link: 'https://en.wikipedia.org/wiki/Kim_Dotcom',
  },
  {
    name: 'Jayson E. Street',
    description: 'A well-known security consultant and speaker famous for his real-world physical security penetration tests.',
    link: 'https://en.wikipedia.org/wiki/Jayson_E._Street',
  },
  {
    name: 'Samy Kamkar',
    description: 'Created the infamous “Samy worm” on MySpace, which gained him over a million followers overnight.',
    link: 'https://en.wikipedia.org/wiki/Samy_Kamkar',
  },
  {
    name: 'Badir Brothers',
    description: 'A notorious hacking group responsible for targeting hundreds of banking and financial institutions.',
    link: 'https://en.wikipedia.org/wiki/Badir_Brothers',
  },
  {
    name: 'Cosmo the God',
    description: 'Teenage hacker and member of UG Nazi, known for high-profile hacks including Twitter and cloud services.',
    link: 'https://en.wikipedia.org/wiki/Cosmo_the_God',
  },
  {
    name: 'Mafiaboy',
    description: 'Teenager responsible for major DDoS attacks on Yahoo, eBay, CNN, and other sites in 2000.',
    link: 'https://en.wikipedia.org/wiki/Mafiaboy',
  },
  {
    name: 'The Jester',
    description: 'A self-proclaimed “hacktivist for good,” known for DDoS attacks against ISIS and other organizations.',
    link: 'https://en.wikipedia.org/wiki/The_Jester_(hacktivist)',
  },
  {
    name: 'The Shadow Brokers',
    description: 'An anonymous group that released hacking tools allegedly used by the NSA.',
    link: 'https://en.wikipedia.org/wiki/The_Shadow_Brokers',
  },
  {
    name: 'Marcus Hutchins',
    description: 'Known as “MalwareTech,” he stopped the WannaCry ransomware attack and later faced hacking charges.',
    link: 'https://en.wikipedia.org/wiki/Marcus_Hutchins',
  },
  {
    name: 'John Draper',
    description: 'Known as “Captain Crunch,” he was a phone phreaker who discovered how to hack AT&T’s long-distance phone systems.',
    link: 'https://en.wikipedia.org/wiki/John_Draper',
  },
  {
    name: 'Rahul Tyagi',
    description: 'Indian cybersecurity expert known for his ethical hacking and awareness contributions.',
    link: 'https://en.wikipedia.org/wiki/Rahul_Tyagi',
  },
  {
    name: 'Ehud Tenenbaum',
    description: 'Known as “The Analyzer,” he led attacks on U.S. government networks in the late 1990s.',
    link: 'https://en.wikipedia.org/wiki/Ehud_Tenenbaum',
  },
  {
    name: 'John McAfee',
    description: 'Creator of the McAfee antivirus software, known for his controversial life and cybersecurity claims.',
    link: 'https://en.wikipedia.org/wiki/John_McAfee',
  },
  {
    name: 'Anna Chapman',
    description: 'Allegedly part of a Russian spy ring, she was arrested by the FBI and later became a media personality.',
    link: 'https://en.wikipedia.org/wiki/Anna_Chapman',
  },
  {
    name: 'Victor F. Tsao',
    description: 'Founder of Linksys, which contributed to cybersecurity and networking advancements.',
    link: 'https://en.wikipedia.org/wiki/Victor_Tsao',
  },
  {
    name: 'Dmitry Sklyarov',
    description: 'Arrested in the U.S. for releasing software that decrypted Adobe’s eBook format, sparking a debate on digital rights.',
    link: 'https://en.wikipedia.org/wiki/Dmitry_Sklyarov',
  },
  {
    name: 'Richard Stallman',
    description: 'Founder of the Free Software Foundation and creator of GNU, advocating for software freedom.',
    link: 'https://en.wikipedia.org/wiki/Richard_Stallman',
  },
  {
    name: 'William “Captain Zap” Marr',
    description: 'Hacked AT&T’s network to manipulate billing, a notable phone phreaker in the 1980s.',
    link: 'https://en.wikipedia.org/wiki/William_Marr',
  },
  {
    name: 'Nellie Bowles',
    description: 'Journalist who covers technology and hacking topics, known for her deep investigative pieces.',
    link: 'https://www.nytimes.com/by/nellie-bowles',
  },
  {
    name: 'Ben Caudill',
    description: 'Cybersecurity expert and founder of Rhino Security Labs, specializing in penetration testing.',
    link: 'https://www.linkedin.com/in/bencaudill/',
  },
  {
    name: 'Dr. Charlie Croom',
    description: 'Director of the Information Warfare Center, tasked with securing U.S. military communications.',
    link: 'https://en.wikipedia.org/wiki/Charlie_Croom',
  },
  {
    name: 'Raphael Gray',
    description: 'Hacked numerous e-commerce sites and stole credit card information, gaining international attention at age 18.',
    link: 'https://en.wikipedia.org/wiki/Raphael_Gray',
  },

];

const HackOfFamePage = () => {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" style={{ padding: '2rem' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Hack of Fame
          </Typography>
          <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
            {hackers.map((hacker, index) => (
              <Card 
                key={index}
                style={{
                  backgroundColor: '#0A0A0A',
                  color: '#00FF41',
                  border: '1px solid #006400',
                  boxShadow: '0 0 15px rgba(0, 255, 65, 0.3)',
                  transition: 'transform 0.3s ease-in-out',
                  fontFamily: 'Roboto Mono, monospace',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 0 20px rgba(0, 255, 65, 0.5)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 0 15px rgba(0, 255, 65, 0.3)';
                }}
              >
                <CardContent style={{ textAlign: 'center' }}>
                  <Avatar 
                    alt={hacker.name} 
                    src={HackerImage} 
                    style={{
                      width: 80, 
                      height: 80, 
                      margin: 'auto', 
                      marginBottom: '1rem', 
                      filter: 'grayscale(100%) brightness(0.8)',
                    }}
                  />
                  <Typography variant="h6" style={{ color: '#90CAF9', fontFamily: 'Roboto Mono, monospace' }}>
                    {hacker.name}
                  </Typography>
                  <Typography variant="body1" style={{ color: '#CCCCCC', fontSize: '0.9rem', marginTop: '0.5rem' }}>
                    {hacker.description}
                  </Typography>
                  <Link href={hacker.link} target="_blank" rel="noopener" style={{ color: '#90CAF9', textDecoration: 'underline', marginTop: '1rem', display: 'inline-block' }}>
                    Learn More
                  </Link>
                </CardContent>
              </Card>
            ))}
          </Masonry>
        </Container>
      </ThemeProvider>
    );
  };
  
  export default HackOfFamePage;
