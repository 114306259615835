// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import LandingPage from './pages/LandingPage';
import AppBarComponent from './components/AppBarComponent';
import ContactPage from './pages/ContactPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fontsource/jetbrains-mono'; // Import the font here
import Footer from './components/Footer';
import './App.css';
import ToolsPage from './pages/ToolsPage';
import LanguagesPage from './pages/LanguagesPage';
import HackOfFamePage from './pages/HackOfFamePage';

function App() {
  return (
   
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppBarComponent />
        <div className="App">
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/languages" element={<LanguagesPage />} />
          <Route path="/hack-of-fame" element={<HackOfFamePage />} />
        </Routes>
        </div>
        <Footer />
      </Router>
    </ThemeProvider>
    
  );
}

export default App;
