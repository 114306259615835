// src/Footer.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { styled } from '@mui/system';
import logo from '../assets/HACK.png';
import { useSpring, animated } from 'react-spring';


const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto',
  width: '100%',
  position: 'relative',
  bottom: 0,
  
  // Media query for screens wider than 768px
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const Logo = styled('img')({
  height: '100px',
  borderRadius: '50%',
});

const Footer = () => {
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const logoSpring = useSpring({ 
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
    config: { tension: 170, friction: 20 }
  });

  const textSpring = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    delay: 500,
  });

  return (
    <FooterContainer>
      <animated.div style={logoSpring}>
        <Logo src={logo} alt="Hack the World Logo" />
      </animated.div>
      <Box>
        <animated.div style={textSpring}>
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} Hack the World
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {currentTime}
          </Typography>
        </animated.div>
      </Box>
      <IconButton
        component={Link}
        href="https://www.linkedin.com/company/hacktheworldwebsite"
        target="_blank"
        rel="noopener"
        color="inherit"
      >
        <LinkedInIcon />
      </IconButton>
      <Typography variant="body2" sx={{ mt: { xs: 2, md: 0 } }}>
        Website built by{' '}
        <a
          href="https://nealfrazier.tech"
          className="nft-link"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'underline' }}
        >
          Neal Frazier Tech
        </a>
      </Typography>
    </FooterContainer>
  );
};

export default Footer;
